.content{
  max-width: 700px;
  padding: 16px;
  border-radius: 8px;
  margin-left: 8px;
}

.content .whoiam p {
  line-height: 1.6;
  margin-bottom: 2px;
}

@media (max-width: 768px) {
  .content {
    width: 90%;
  }
}

.content p {
  /* font-family: Merriweather; */
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 10px;
  font-size: 18px;
  color: #222222;
}

h1 {
  margin-bottom: 20px;
}

h2, h3, h4{
  margin-top: 50px;
  margin-bottom: 20px;
}

.ghlink {
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
}

.repo-description {
  padding-left: 20px;
}

.content .my_pull_requests p {
  font-size: 1rem;
}
.content .my_pull_requests li {
  font-size: 1rem;
}
.content .my_pull_requests h5 {
  font-size: 1.1rem;
}

.commentary {
  font-style: italic;
}

.pr_body {
  overflow-wrap: break-word;
}

.skill-title {
  width: 130px;
  display: inline-block;
}

.skill-star {
  width: 20px;
  display: inline-block;
  /* vertical-align: top; */
}

.skill-star svg {
  vertical-align: baseline;
}