nav.toc {
  width: 30%;
  min-width: 220px;
  padding: 20px;
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  max-height: calc(100vh - 70px);
  overflow: auto;
  /* margin-top: 150px; */
}


nav.toc ul li {
  margin-bottom: 10px;
}

nav.toc a {
  /* color: #222222; */
  text-decoration: none;
}

nav.toc .head3 {
  margin-left: 10px;
  margin-bottom: 6px;
  list-style-type: circle;
}

.head4{
  margin-left: 20px;
  list-style-type: square;
}

@media (max-width: 768px) {
  nav.toc {
    display: none;
    width: 220px;
    min-width: 220px;
    padding: 20px;
    align-self: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
    max-height: calc(100vh - 70px);
    overflow: auto;
    margin-top: 150px;
  }
  
  nav.toc.collapsed {
    display: none;
  }
}

